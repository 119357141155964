<template>
  <div class="login">
    <div class="container">
      <div class="d-flex justify-content-center align-items-center vh-100">
        <form class="form login-form" @submit.prevent="onSubmit">
          <div class="alert alert-danger" v-if="errorMessage" role="alert">
            {{ errorMessage }}
          </div>
          <div class="form-group">
            <label for="email" class="mb-3">Email</label>
            <input
              type="email"
              id="email"
              class="form-control"
              placeholder="請輸入Email"
              size="40"
              v-model="email"
            />
            <span class="text-danger" v-if="errors.email"> Email必須填寫 </span>
          </div>
          <div class="form-grop mb-5">
            <label for="password" class="mb-3">密碼</label>
            <input
              type="password"
              id="password"
              autocomplete="off"
              class="form-control"
              placeholder="請輸入密碼"
              v-model="password"
            />
            <span class="text-danger" v-if="errors.password">密碼必須填寫</span>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-primary btn-block btn-lg">
              登入
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { singIn } from "connection/admin/auth";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
export default {
  name: "login",
  setup() {
    //login
    const { handleSubmit, errors } = useForm({
      //驗證
      validationSchema: yup.object({
        email: yup.string().required().email(),
        password: yup.string().required(),
      }),
    });

    const onSubmit = handleSubmit((user) => {
      login(user.email, user.password);
    });
    const { value: email } = useField("email");
    const { value: password } = useField("password");
    const router = useRouter();
    const errorMessage = ref("");
    const login = async (email, password) => {
      errorMessage.value = "";
      try {
        await singIn({ email, password });
        router.push("/administration");
      } catch (error) {
        errorMessage.value = "Email或密碼不正確";
      }
    };
    return {
      email,
      password,
      errorMessage,
      onSubmit,
      errors,
    };
  },
};
</script>
